import axios, { AxiosInstance, AxiosTransformer } from 'axios'
import { IBrewery, ICoordinates } from '../models/brewery'

type BreweryType =
  'micro'
  | 'nano'
  | 'regional'
  | 'brewpup'
  | 'large'
  | 'planning'
  | 'bar'
  | 'contract'
  | 'proprietor'
  | 'closed'

interface Brewery {
  id: number,
  name: string,
  brewery_type: BreweryType,
  street: string,
  address_2: string | null,
  address_3: string | null,
  city: string,
  state: string,
  county_province: null,
  postal_code: string,
  country: string,
  longitude: number,
  latitude: number,
  phone: string,
  website_url: string,
  updated_at: Date,
  created_at: Date
}

type BreweryTransformer = (serverBrewery: Brewery) => IBrewery
type CoordinatesExtractor = (serverBrewery: Brewery) => ICoordinates
type PhoneFormatter = (serverBrewery: Brewery) => string

const phoneFormatter: PhoneFormatter = (serverBrewery) => {
  // This can be switch to a factory based on country
  const { phone, country } = serverBrewery
  switch (country) {
    case 'United States':
      return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)} - ${phone.slice(6)}`
    default:
      console.log(country, phone)
      return ''
  }
}

const breweryTransformer: BreweryTransformer = (serverBrewery) => {
  return {
    id: serverBrewery.id,
    name: serverBrewery.name,
    phoneNumber: phoneFormatter(serverBrewery),
    webpage: serverBrewery.website_url,
  }
}

type SearchBreweriesByName = (name: string) => any;//Promise<Brewery[]>;
type SearchBreweriesByCity = (city: string) => Promise<Brewery[]>;


export interface ListBreweriesOptions {
  by_name?: string
  by_city?: string
  by_postal?: string
  by_state?: string
  by_type?: string
  page?: number
  per_page?: number
  sort?: string
}

interface IOpenBreweryDBAPI {
  searchBreweriesByName: SearchBreweriesByName
  // searchBreweriesByCity: SearchBreweriesByCity
  listBreweries: (options: ListBreweriesOptions) => Promise<IBrewery[]>
}

class OpenBreweryDBAPI implements IOpenBreweryDBAPI {
  private instance: AxiosInstance

  constructor() {
    this.instance = axios.create({
      baseURL: 'https://api.openbrewerydb.org',
    })
  }

  searchBreweriesByName(name) {
    return this.instance.get('/breweries', {
      params: {
        by_name: encodeURI(name),
        page: 1,
        sort: 'name',
      },
    }).then(res => {
      console.log(res)
      return (res.data)
    })
  }

  listBreweries(options: ListBreweriesOptions): Promise<IBrewery[]> {
    return this.instance.get('/breweries', {
      params: options,
      transformResponse: [
        ...axios.defaults.transformResponse as AxiosTransformer[],
        (breweries: Brewery[]) => breweries.map(breweryTransformer),
      ],
    }).then(res => (res.data))
  }

  getFullListJson() {
    return axios.get('https://raw.githubusercontent.com/openbrewerydb/openbrewerydb/master/breweries.json')
      .then(resp => resp.data)
  }
}

export default new OpenBreweryDBAPI()
