import Layout from '../components/layout'
import * as React from 'react'
import { Button } from '@material-ui/core'
import OpenBreweryDBAPI from '../api/OpenBreweryDBAPI'
import { API, graphqlOperation } from 'aws-amplify'
import { createBrewery } from '../graphql/mutations'

function transform(brewery) {
  return {
    name: brewery.name,
    type: brewery.brewery_type,
    phoneNumber: brewery.phone,
    webpage: brewery.website_url,
    coordinates: {
      lat: brewery.latitude,
      lon: brewery.longitude,
    },
    address: {
      line1: brewery.street,
      line2: brewery.address_2,
      line3: brewery.address_3,
      city: brewery.city,
      country: brewery.country,
      postalcode: brewery.postal_code,
      state: brewery.state,
    },
  }
}

export default function Import() {
  const [done, setDone] = React.useState(false)

  async function importBreweries() {
    const breweries = await OpenBreweryDBAPI.getFullListJson()
    Promise.all(breweries
      .map(transform)
      .map(b => (API.graphql(graphqlOperation(createBrewery, {
        input: b,
      })))))
      .then(() => setDone(true))
  }

  return (
    <Layout>
      <h1>Import Breweries from OBDB</h1>
      <Button onClick={importBreweries}>Import</Button>
      {done && <h1>Import Completed</h1>}
    </Layout>
  )
}
